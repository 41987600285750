import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  padding: 16px;
  padding-top: 36px;
  padding-bottom: 48px;
  max-width: 720px;
  width: 100%;
  margin: auto;
  font-family: 'Miller Text', helvetica, sans-serif;
  font-family: helvetica;
  font-size: 17px;
  line-height: 1.4;
`;
const BluePeriod = () => {
  return (
    <div
      style={{
        padding: '16px',
      }}
    >
      <Link style={{ fontFamily: 'helvetica' }} to={'/'}>
        Home
      </Link>
      <Container>
        IF IT MADE any real sense--and it doesn't even begin to--I think I might
        be inclined to dedicate this account, for whatever it's worth,
        especially if it's the least bit ribald in parts, to the memory of my
        late, ribald stepfather, Robert Agadganian, Jr. Bobby--as everyone, even
        I, called him --died in 1947, surely with a few regrets, but without a
        single gripe, of thrombosis. He was an adventurous, extremely magnetic,
        and generous man. (After having spent so many years laboriously
        begrudging him those picaresque adjectives, I feel it's a matter of life
        and death to get them in here.)
        <br />
        <br />
        My mother and father were divorced during the winter of 1928, when I was
        eight, and mother married Bobby Agadganian late that spring. A year
        later, in the Wall Street Crash, Bobby lost everything he and mother
        had, with the exception, apparently, of a magic wand. In any case,
        practically overnight, Bobby turned himself from a dead stockbroker and
        incapacitated bon vivant into a live, if somewhat unqualified,
        agent-appraiser for a society of independent American art galleries and
        fine arts museums. A few weeks later, early in 1930, our rather mixed
        threesome moved from New York to Paris, the better for Bobby to ply his
        new trade. Being a cool, not to say an ice-cold, ten at the time, I took
        the big move, so far as I know, untraumatically. It was the move back to
        New York, nine years later, three months after my mother died, that
        threw me, and threw me terribly.
        <br />
        <br />I remember a significant incident that occurred just a day or two
        after Bobby and I arrived in New York. I was standing up in a very
        crowded Lexington Avenue bus, holding on to the enamel pole near the
        driver's seat, buttocks to buttocks with the chap behind me. For a
        number of blocks the driver had repeatedly given those of us bunched up
        near the front door a curt order to "step to the rear of the vehicle."
        Some of us had tried to oblige him. Some of us hadn't. At length, with a
        red light in his favor, the harassed man swung around in his seat and
        looked up at me, just behind him. At nineteen, I was a hatless type,
        with a flat, black, not particularly clean, Continental-type pompadour
        over a badly broken-out inch of forehead. He addressed me in a lowered,
        an almost prudent tone of voice. "All right, buddy," he said, "let's
        move that ass." It was the "buddy," I think, that did it. Without even
        bothering to bend over a little--that is, to keep the conversation at
        least as private, as de bon gout, as he'd kept it--I informed him, in
        French, that he was a rude, stupid, overbearing imbecile, and that he'd
        never know how much I detested him. Then, rather elated, I stepped to
        the rear of the vehicle.
        <br />
        <br />
        Things got much worse. One afternoon, a week or so later, as I was
        coming out of the Ritz Hotel, where Bobby and I were indefinitely
        stopping, it seemed to me that all the seats from all the buses in New
        York had been unscrewed and taken out and set up in the street, where a
        monstrous game of Musical Chairs was in full swing. I think I might have
        been willing to join the game if I had been granted a special
        dispensation from the Church of Manhattan guaranteeing that all the
        other players would remain respectfully standing till I was seated. When
        it became clear that nothing of the kind was forthcoming, I took more
        direct action. I prayed for the city to be cleared of people, for the
        gift of being alone--a-l-o-n-e: which is the one New York prayer that
        rarely gets lost or delayed in channels, and in no time at all
        everything I touched turned to solid loneliness. Mornings and early
        afternoons, I attended--bodily--an art school on Forty-eighth and
        Lexington Avenue, which I loathed. (The week before Bobby and I had left
        Paris, I had won three first-prize awards at the National Junior
        Exhibition, held at the Freiburg Galleries. Throughout the voyage to
        America, I used our stateroom mirror to note my uncanny physical
        resemblance to El Greco.) Three late afternoons a week I spent in a
        dentist's chair, where, within a period of a few months, I had eight
        teeth extracted, three of them front ones. The other two afternoons I
        usually spent wandering through art galleries, mostly on Fifty-seventh
        Street, where I did all but hiss at the American entries. Evenings, I
        generally read. I bought a complete set of the Harvard Classics--chiefly
        because Bobby said we didn't have room for them in our suite--and
        <br />
        <br />
        rather perversely read all fifty volumes. Nights, I almost invariably
        set up my easel between the twin beds in the room I shared with Bobby,
        and painted. In one month alone, according to my diary for 1939, I
        completed eighteen oil paintings. Noteworthily enough, seventeen of them
        were self-portraits. Sometimes, however, possibly when my Muse was being
        capricious, I set aside my paints and drew cartoons. One of them I still
        have. It shows a cavernous view of the mouth of a man being attended by
        his dentist. The man's tongue is a simple, U.S. Treasury hundred dollar
        bill, and the dentist is saying, sadly, in French, "I think we can save
        the molar, but I'm afraid that tongue will have to come out." It was an
        enormous favorite of mine.
        <br />
        <br />
        As roommates, Bobby and I were neither more nor less compatible than
        would be, say, an exceptionally live-and-let-live Harvard senior, and an
        exceptionally unpleasant Cambridge newsboy. And when, as the weeks went
        by, we gradually discovered that we were both in love with the same
        deceased woman, it was no help at all. In fact, a ghastly little
        after-you-Alphonse relationship grew out of the discovery. We began to
        exchange vivacious smiles when we bumped into each other on the
        threshold of the bathroom.
        <br />
        <br />
        One week in May of 1939, about ten months after Bobby and I checked into
        the Ritz, I saw in a Quebec newspaper (one of sixteen French -language
        newspapers and periodicals I had blown myself a subscription to) a
        quarter-column advertisement that had been placed by the direction of a
        Montreal correspondence art school. It advised all qualified
        instructors--it as much as said, in fact, that it couldn't advise them
        fortenwnt enough--to apply immediately for employment at the newest,
        most progressive, correspondence art school in Canada. Candidate
        instructors, it stipulated, were to have a fluent knowledge of both the
        French and English languages, and only those of temperate habits and
        unquestionable character need apply. The summer session at Les Amis Des
        Vieux Maitres was officially to open on 10 June. Samples of work, it
        said, should represent both the academic and commercial fields of art,
        and were to be submitted to Monsieur I. Yoshoto, directeur, formerly of
        the Imperial Academy of Fine Arts, Tokyo.
        <br />
        <br />
        Instantly, feeling almost insupportably qualified, I got out Bobby's
        Hermes-Baby typewriter from under his bed and wrote, in French, a long,
        intemperate letter to M. Yoshoto--cutting all my morning classes at the
        art school on Lexington Avenue to do it. My opening paragraph ran some
        three pages, and very nearly smoked. I said I was twenty-nine and a
        great-nephew of Honore Daumier. I said I had just left my small estate
        in the South of France, following the death of my wife, to come to
        America to stay--temporarily, I made it clear--with an invalid relative.
        I had been painting, I said, since early childhood, but that, following
        the advice of Pablo Picasso, who was one of the oldest and dearest
        friends of my parents, I had never exhibited. However, a number of my
        oil paintings and water colors were now hanging in some of the finest,
        and by no means nouveau riche, homes in Paris, where they had gagne
        considerable attention from some of the most formidable critics of our
        day. Following, I said, my wife's untimely and tragic death, of an
        ulceration cancgreuse, I had earnestly thought I would never again set
        brush to canvas. But recent financial losses had led me to alter my
        earnest resolution. I said I would be most honored to submit samples of
        my work to Les Amis Des Vieux Maitres, just as soon as they were sent to
        me by my agent in Paris, to whom I would write, of course, tres presse.
        I remained, most respectfully, Jean de Daumier-Smith.
        <br />
        <br />
        It took me almost as long to select a pseudonym as it had taken me to
        write the whole letter.
        <br />
        <br />I wrote the letter on overlay tissue paper. However, I sealed it
        in a Ritz envelope. Then, after applying a special-delivery stamp I'd
        found in Bobby's top drawer, I took the letter down to the main mail
        drop in the lobby. I stopped on the way to put the mail clerk (who
        unmistakably loathed me) on the alert for de Daumier-Smith's future
        incoming mail. Then, around two-thirty, I slipped into my one-forty
        -five anatomy class at the art school on Forty-eighth Street. My
        classmates seemed, for the first time, like a fairly decent bunch.
        <br />
        <br />
        During the next four days, using all my spare time, plus some time that
        didn't quite belong to me, I drew a dozen or more samples of what I
        thought were typical examples of American commercial art. Working mostly
        in washes, but occasionally, to show off, in line, I drew people in
        evening clothes stepping out of limousines on opening nights-- lean,
        erect, super-chic couples who had obviously never in their lives
        inflicted suffering as a result of underarm carelessness--couples, in
        fact, who perhaps didn't have any underarms. I drew suntanned young
        giants in white dinner jackets, seated at white tables alongside
        turquoise swimming pools, toasting each other, rather excitedly, with
        highballs made from a cheap but ostensibly ultrafashionable brand of rye
        whisky. I drew ruddy, billboard-genic children, beside themselves with
        delight and good health, holding up their empty bowls of breakfast food
        and pleading, good-naturedly, for more. I drew laughing, high-breasted
        girls aquaplaning without a care in the world, as a result of being
        amply protected against such national evils as bleeding gums, facial
        blemishes, unsightly hairs, and faulty or inadequate life insurance. I
        drew housewives who, until they reached for the right soap flakes, laid
        themselves wide open to straggly hair, poor posture, unruly children,
        disaffected husbands, rough (but slender) hands, untidy (but enormous)
        kitchens.
        <br />
        <br />
        When the samples were finished, I mailed them immediately to M. Yoshoto,
        along with a half-dozen or so non-commercial paintings of mine that I'd
        brought with me from France. I also enclosed what I thought was a very
        casual note that only just began to tell the richly human little story
        of how, quite alone and variously handicapped, in the purest romantic
        tradition, I had reached the cold, white, isolating summits of my
        profession.
        <br />
        <br />
        The next few days were horribly suspenseful, but before the week was
        out, a letter came from M. Yoshoto accepting me as an instructor at Les
        Amis Des Vieux Maitres. The letter was written in English, even though I
        had written in French. (I later gathered that M. Yoshoto, who knew
        French but not English, had, for some reason, assigned the writing of
        the letter to Mme. Yoshoto, who had some working knowledge of English.)
        M. Yoshoto said that the summer session would probably be the busiest
        session of the year, and that it started on 24 June. This gave me almost
        five weeks, he pointed out, to settle my affairs. He offered me his
        unlimited sympathy for, in effect, my recent emotional and financial
        setbacks. He hoped that I would arrange myself to report at Les Amis Des
        Vieux Maitres on Sunday, 23 June, in order to learn of my duties and to
        become "firm friends" with the other instructors (who, I later learned,
        were two in number, and consisted of M. Yoshoto and Mme. Yoshoto). He
        deeply regretted that it was not the school's policy to advance
        transportation fare to new instructors. Starting salary was twenty-eight
        dollars a week-which was not, M. Yoshoto said he realized, a very large
        sum of funds, but since it included bed and nourishing food, and since
        he sensed in me the true vocationary spirit, he hoped I would not feel
        cast down with vigor. He awaited a telegram of formal acceptance from me
        with eagerness and my arrival with a spirit of pleasantness, and
        remained, sincerely, my new friend and employer, I. Yoshoto, formerly of
        the Imperial Academy of Fine Arts, Tokyo.
        <br />
        <br />
        My telegram of formal acceptance went out within five minutes. Oddly
        enough, in my excitement, or quite possibly from a feeling of guilt
        because I was using Bobby's phone to send the wire, I deliberately sat
        on my prose and kept the message down to ten words.
        <br />
        <br />
        That evening when, as usual, I met Bobby for dinner at seven o'clock in
        the Oval Room, I was annoyed to see that he'd brought a guest along. I
        hadn't said or implied a word to him about my recent, extracurricular
        doings, and I was dying to make this final news-break--to scoop him
        thoroughly --when we were alone. The guest was a very attractive young
        lady, then only a few months divorced, whom Bobby had been seeing a lot
        of and whom I'd met on several occasions. She was an altogether charming
        person whose every attempt to be friendly to me, to gently persuade me
        to take off my armor, or at least my helmet, I chose to interpret as an
        implied invitation to join her in bed at my earliest convenience--that
        is, as soon as Bobby, who clearly was too old for her, could be given
        the slip. I was hostile and laconic throughout dinner. At length, while
        we were
        <br />
        <br />
        having coffee, I tersely outlined my new plans for the summer. When I'd
        finished, Bobby put a couple of quite intelligent questions to me. I
        answered them coolly, overly briefly, the unimpeachable crown prince of
        the situation.
        <br />
        <br />
        "Oh, it sounds very exciting!" said Bobby's guest, and waited, wantonly,
        for me to slip her my Montreal address under the table.
        <br />
        <br />
        "I thought you were going to Rhode Island with me," Bobby said.
        <br />
        <br />
        "Oh, darling, don't be a horrible wet blanket," Mrs. X said to him.
        <br />
        <br />
        "I'm not, but I wouldn't mind knowing a little more about it," Bobby
        said. But I thought I could tell from his manner that he was already
        mentally exchanging his train reservations for Rhode Island from a
        compartment to a lower berth.
        <br />
        <br />
        "I think it's the sweetest, most complimentary thing I ever heard in my
        life," Mrs. X said warmly to me. Her eyes sparkled with depravity.
        <br />
        <br />
        The Sunday that I stepped on to the platform at Windsor Station in
        Montreal, I was wearing a doublebreasted, beige gabardine suit (that I
        had a damned high opinion of), a navy-blue flannel shirt, a solid
        yellow, cotton tie, brown-and-white shoes, a Panama hat (that belonged
        to Bobby and was rather too small for me), and a reddish-brown
        moustache, aged three weeks. M. Yoshoto was there to meet me. He was a
        tiny man, not more than five feet tall, wearing a rather soiled linen
        suit, black shoes, and a black felt hat with the brim turned up all
        around. He neither smiled, nor, as I remember, said anything to me as we
        shook hands. His expression--and my word for it came straight out of a
        French edition of Sax Rohmer's Fu Manchu books--was inscrutable. For
        some reason, I was smiling from ear to ear. I couldn't even turn it
        down, let alone off.
        <br />
        <br />
        It was a bus ride of several miles from Windsor Station to the school. I
        doubt if M. Yoshoto said five words the whole way. Either in spite, or
        because, of his silence, I talked incessantly, with my legs crossed,
        ankle on knee, and constantly using my sock as an absorber for the
        perspiration on my palm. It seemed urgent to me not only to reiterate my
        earlier lies--about my kinship with Daumier, about my deceased wife,
        about my small estate in the South of France--but to elaborate on them.
        At length, in effect to spare myself from dwelling on these painful
        reminiscences (and they were beginning to feel a little painful), I
        swung over to the subject of my parents' oldest and dearest friend:
        Pablo Picasso. Le pauvre Picasso, as I referred to him. (I picked
        Picasso, I might mention, because he seemed to me the French painter who
        was best-known in America. I roundly considered Canada part of America.)
        For M. Yoshoto's benefit, I recalled, with a showy amount of natural
        compassion for a fallen giant, how many times I had said to him, "M.
        Picasso, ofi allez vous?" and how, in response to this all-penetrating
        question, the master had never failed to walk slowly, leadenly, across
        his studio to look at a small reproduction of his "Les Saltimbanques"
        and the glory, long forfeited, that had been his. The trouble with
        Picasso, I explained to M. Yoshoto as we got out of the bus, was that he
        never listened to anybody--even his closest friends.
        <br />
        <br />
        In 1939, Les Amis Des Vieux Maitres occupied the second floor of a
        small, highly unendowed-looking, three -story building--a tenement
        building, really--in the Verdun, or least attractive, section of
        Montreal. The school was directly over an orthopedic appliances shop.
        One large room and a tiny, boltless latrine were all there was to Les
        Amis Des Vieux Maitres itself. Nonetheless, the moment I was inside, the
        place seemed wondrously presentable to me. There was a very good reason.
        The walls of the "instructors' room" were hung with many framed
        pictures--all water colors --done by M. Yoshoto. Occasionally, I still
        dream of a certain white goose flying through an extremely pale-blue
        sky, with --and it was one of the most daring and accomplished feats of
        craftsmanship I've ever seen--the blueness of the sky, or an ethos of
        the blueness of the sky, reflected in the bird's feathers. The picture
        was hung just behind Mme. Yoshoto's desk. It made the room--it and one
        or two other pictures close to it in quality.
        <br />
        <br />
        Mme. Yoshoto, in a beautiful, black and cerise silk kimono, was sweeping
        the floor with a short-handled broom when M. Yoshoto and I entered the
        instructors' room. She was a gray-haired woman, surely a head taller
        than her husband, with features that looked rather more Malayan than
        Japanese. She left off sweeping and came forward, and M. Yoshoto briefly
        introduced us. She seemed to me every bit as inscrutable as M.
        <br />
        <br />
        Yoshoto, if not more so. M. Yoshoto then offered to show me to my room,
        which, he explained (in French) had recently been vacated by his son,
        who had gone to British Columbia to work on a farm. (After his long
        silence in the bus, I was grateful to hear him speak with any
        continuity, and I listened rather vivaciously.) He started to apologize
        for the fact that there were no chairs in his son's room --only floor
        cushions-- but I quickly gave him to believe that for me this was little
        short of a godsend. (In fact, I think I said I hated chairs. I was so
        nervous that if he had informed me that his son's room was flooded,
        night and day, with a foot of water, I probably would have let out a
        little cry of pleasure. I probably would have said I had a rare foot
        disease, one that required my keeping my feet wet eight hours daily.)
        Then he led me up a creaky wooden staircase to my room. I told him on
        the way, pointedly enough, that I was a student of Buddhism. I later
        found out that both he and Mme. Yoshoto were Presbyterians.
        <br />
        <br />
        Late that night, as I lay awake in bed, with Mme. Yoshoto's Japanese
        -Malayan dinner still en masse and riding my sternum like an elevator,
        one or the other of the Yoshotos began to moan in his or her sleep, just
        the other side of my wall. It was a high, thin, broken moan, and it
        seemed to come less from an adult than from either a tragic, subnormal
        infant or a small malformed animal. (It became a regular nightly
        performance. I never did find out which of the Yoshotos it came from,
        let alone why.) When it became quite unendurable to listen to from a
        supine position, I got out of bed, put on my slippers, and went over in
        the dark and sat down on one of the floor cushions. I sat crosslegged
        for a couple of hours and smoked cigarettes, squashing them out on the
        instep of my slipper and putting the stubs in the breast pocket of my
        pyjamas. (The Yoshotos didn't smoke, and there were no ashtrays anywhere
        on the premises.) I got to sleep around five in the morning.
        <br />
        <br />
        At six-thirty, M. Yoshoto knocked on my door and advised me that
        breakfast would be served at six-forty-five. He asked me, through the
        door, if I'd slept well, and I answered, "Oui!" I then dressed--putting
        on my blue suit, which I thought appropriate for an instructor on the
        opening day of school, and a red Sulka tie my mother had given me-- and,
        without washing, hurried down the hall to the Yoshotos' kitchen.
        <br />
        <br />
        Mme. Yoshoto was at the stove, preparing a fish breakfast. M. Yoshoto,
        in his B.V.D.'s and trousers, was seated at the kitchen table, reading a
        Japanese newspaper. He nodded to me, non-committally. Neither of them
        had ever looked more inscrutable. Presently, some sort of fish was
        served to me on a plate with a small but noticeable trace of coagulated
        catsup along the border. Mme. Yoshoto asked me, in English--and her
        accent was unexpectedly charming--if I would prefer an egg, but I said,
        "Non, non, madame--merci!" I said I never ate eggs. M. Yoshoto leaned
        his newspaper against my water glass, and the three of us ate in
        silence; that is, they ate and I systematically swallowed in silence.
        <br />
        <br />
        After breakfast, without having to leave the kitchen, M. Yoshoto put on
        a collarless shirt and Mme. Yoshoto took off her apron, and the three of
        us filed rather awkwardly downstairs to the instructors' room. There, in
        an untidy pile on M. Yoshoto's broad desk, lay some dozen or more
        unopened, enormous, bulging, Manilla envelopes. To me, they had an
        almost freshly brushed -and-combed look, like new pupils. M. Yoshoto
        assigned me to my desk, which was on the far, isolated side of the room,
        and asked me to be seated. Then, with Mme. Yoshoto at his side, he broke
        open a few of the envelopes. He and Mme. Yoshoto seemed to examine the
        assorted contents with some sort of method, consulting each other, now
        and then, in Japanese, while I sat across the room, in my blue suit and
        Sulka tie, trying to look simultaneously alert and patient and, somehow,
        indispensable to the organization. I took out a handful of soft-lead
        drawing pencils, from my inside jacket pocket, that I'd brought from New
        York with me, and laid them out, as noiselessly as possible, on the
        surface of my desk. Once, M. Yoshoto glanced over at me for some reason,
        and I flashed him an excessively winning smile. Then, suddenly, without
        a word or a look in my direction, the two of them sat down at their
        respective desks and went to work. It was about seven-thirty.
        <br />
        <br />
        Around nine, M. Yoshoto took off his glasses, got up and padded over to
        my desk with a sheaf of papers in his hand. I'd spent an hour and a half
        doing absolutely nothing but trying to keep my stomach from growling
        audibly. I quickly stood up as he came into
        <br />
        <br />
        my vicinity, stooping a trifle in order not to look disrespectfully
        tall. He handed me the sheaf of papers he'd brought over and asked me if
        I would kindly translate his written corrections from French into
        English. I said, "Oui, monsieur!" He bowed slightly, and padded back to
        his own desk. I pushed my handful of soft-lead drawing pencils to one
        side of my desk, took out my fountain pen, and fell--very nearly
        heartbroken--to work. Like many a really good artist, M. Yoshoto taught
        drawing not a whit better than it's
        <br />
        <br />
        taught by a so-so artist who has a nice flair for teaching. With his
        practical overlay work--that is to say, his tracing-paper drawings
        imposed over the student's drawings-- along with his written comments on
        the backs of the drawings--he was quite able to show a reasonably
        talented student how to draw a recognizable pig in a recognizable sty,
        or even a picturesque pig in a picturesque sty. But he couldn't for the
        life of him show anyone how to draw a beautiful pig in a beautiful sty
        (which, of course, was the one little technical bit his better students
        most greedily wanted sent to them through the mail). It was not, need I
        add, that he was consciously or unconsciously being frugal of his
        talent, or deliberately unprodigal of it, but that it simply wasn't his
        to give away. For me, there was no real element of surprise in this
        ruthless truth, and so it didn't waylay me. But it had a certain
        cumulative effect, considering where I was sitting, and by the time
        lunch hour rolled around, I had to be very careful not to smudge my
        translations with the sweaty heels of my hands. As if to make things
        still more oppressive, M. Yoshoto's handwriting was just barely legible.
        At any rate, when it came time for lunch, I declined to join the
        Yoshotos. I said I had to go to the post office. Then I almost ran down
        the stairs to the street and began to walk very rapidly, with no
        direction at all, through a maze of strange, underprivileged-looking
        streets. When I came to a lunch bar, I went inside and bolted four
        "Coney Island Red-Hots" and three muddy cups of coffee.
        <br />
        <br />
        On the way back to Les Amis Des Vieux Maitres, I began to wonder, first
        in a familiar, faint-hearted way that I more or less knew from
        experience how to handle, then in an absolute panic, if there had been
        anything personal in M. Yoshoto's having used me exclusively as a
        translator all morning. Had old Fu Manchu known from the beginning that
        I was wearing, among other misleading attachments and effects, a
        nineteen-year-old boy's moustache? The possibility was almost
        unendurable to consider. It also tended to eat slowly away at my sense
        of justice. Here I was --a man who had won three first-prizes, a very
        close friend of Picasso's (which I actually was beginning to think I
        was)--being used as a translator. The punishment didn't begin to fit the
        crime. For one thing, my moustache, however sparse, was all mine; it
        hadn't been put on with spirit gum. I felt it reassuringly with my
        fingers as I hurried back to school. But the more I thought about the
        whole affair, the faster I walked, till finally I was almost trotting,
        as if any minute I half-expected to be stoned from all directions.
        Though I'd taken only forty minutes or so for lunch, both the Yoshotos
        were at their desks and at work when I got back. They didn't look up or
        give any sign that they'd heard me come in. Perspiring and out of
        breath, I went over and sat down at my desk. I sat rigidly still for the
        next fifteen or twenty minutes, running all kinds of brand-new little
        Picasso anecdotes through my head, just in case M. Yoshoto suddenly got
        up and came over to unmask me. And, suddenly, he did get up and come
        over. I stood up to meet him--head on, if necessary-- with a fresh
        little Picasso story, but, to my horror, by the time he reached me I was
        minus the plot. I chose the moment to express my admiration for the
        goose-in-flight picture hanging over Mme. Yoshoto. I praised it lavishly
        at some length. I said I knew a man in Paris--a very wealthy paralytic,
        I said --who would pay M. Yoshoto any price at all for the picture. I
        said I could get in touch with him immediately if M. Yoshoto was
        interested. Luckily, however, M. Yoshoto said the picture belonged to
        his cousin, who was away visiting relatives in Japan. Then, before I
        could express my regret, he asked me--addressing me as M.
        DaumierSmith--if I would kindly correct a few lessons. He went over to
        his desk and returned with three enormous, bulging envelopes, and placed
        them on my desk. Then, while I stood dazed and incessantly nodding and
        feeling my jacket where my drawing pencils had been repocketed, M.
        Yoshoto explained to me the school's method of instruction (or, rather,
        its nonexistent method of instruction). After he'd returned to his own
        desk, it took me several minutes to pull myself together.
        <br />
        <br />
        All three students assigned to me were English-language students. The
        first was a twenty-three -year-old Toronto housewife, who said her
        professional name was Bambi Kramer, and advised the school to address
        her mail accordingly. All new students at Les Amis Des Vieux Maitres
        were requested to fill out questionnaire forms and to enclose
        photographs of themselves. Miss Kramer had enclosed a glossy, eight by
        ten print of herself wearing an anklet, a strapless bathing suit, and a
        white -duck sailor's cap. On her questionnaire form she stated that her
        favorite artists were Rembrandt and Walt Disney. She said she only hoped
        that she could some day emulate them. Her sample drawings were clipped,
        rather subordinately, to her photograph. All of them were arresting. One
        of them was unforgettable. The unforgettable one was done in florid wash
        colors, with a caption that read: "Forgive Them Their Trespasses." It
        showed three small boys fishing in an odd-looking body of water, one of
        their jackets draped over a "No Fishing!" sign. The tallest boy, in the
        foreground of the picture, appeared to have rickets in one leg and
        elephantiasis in the other--an effect, it was clear, that Miss Kramer
        had deliberately used to show that the boy was standing with his feet
        slightly apart.
        <br />
        <br />
        My second student was a fifty-six-year-old "society photographer" from
        Windsor, Ontario, named R. Howard Ridgefield, who said that his wife had
        been after him for years to branch over into the painting racket. His
        favorite artists were Rembrandt, Sargent, and "Titan," but he added,
        advisedly, that he himself didn't care to draw along those lines. He
        said he was mostly interested in the satiric rather than the arty side
        of painting. To support this credo, he submitted a goodly number of
        original drawings and oil paintings. One of his pictures--the one I
        think of as his major picture --has been as recallable to me, over the
        years, as, say, the lyrics of "Sweet Sue" or "Let Me Call You
        Sweetheart." It satirized the familiar, everyday tragedy of a chaste
        young girl, with belowshoulder-length blond hair and udder-size breasts,
        being criminally assaulted in church, in the very shadow of the altar,
        by her minister. Both subjects' clothes were graphically in disarray.
        Actually, I was much less struck by the satiric implications of the
        picture than I was by the quality of workmanship that had gone into it.
        If I hadn't known they were living hundreds of miles apart, I might have
        sworn Ridgefield had had some purely technical help from Bambi Kramer.
        <br />
        <br />
        Except under pretty rare circumstances, in any crisis, when I was
        nineteen, my funny bone invariably had the distinction of being the very
        first part of my body to assume partial or complete paralysis.
        Ridgefield and Miss Kramer did many things to me, but they didn't come
        at all close to amusing me. Three or four times while I was going
        through their envelopes, I was tempted to get up and make a formal
        protest to M. Yoshoto. But I had no clear idea just what sort of form my
        protest might take. I think I was afraid I might get over to his desk
        only to report, shrilly: "My mother's dead, and I have to live with her
        charming husband, and nobody in New York speaks French, and there aren't
        any chairs in your son's room. How do you expect me to teach these two
        crazy people how to draw?" In the end, being long self-trained in taking
        despair sitting down, I managed very easily to keep my seat. I opened my
        third student's envelope.
        <br />
        <br />
        My third student was a nun of the order of Sisters of St. Joseph, named
        Sister Irma, who taught "cooking and drawing" at a convent elementary
        school just outside Toronto. And I haven't any good ideas concerning
        where to start to describe the contents of her envelope. I might just
        first mention that, in place of a photograph of herself, Sister Irma had
        enclosed, without explanation, a snapshot of her convent. It occurs to
        me, too, that she left blank the line in her questionnaire where the
        student's age was to be filled in. Otherwise, her questionnaire was
        filled out as perhaps no questionnaire in this world deserves to be
        filled out. She had been born and raised in Detroit, Michigan, where her
        father had been a "checker for Ford automobiles." Her academic education
        consisted of one year of high school. She had had no formal instruction
        in drawing. She said the only reason she was teaching it was that Sister
        somebody had passed on and Father Zimmermann (a name that particularly
        caught my eye, because it was the name of the dentist who had pulled out
        eight of my teeth)-Father Zimmermann had picked her to fill in. She said
        she had "34 kittys in my cooking class and 18 kittys in my drawing
        class." Her hobbies were loving her Lord and the Word of her Lord and
        "collecting leaves but
        <br />
        <br />
        only when they are laying right on the ground." Her favorite painter was
        Douglas Bunting. (A name, I don't mind saying, I've tracked down to many
        a blind alley, over the years.) She said her kittys always liked to
        "draw people when they are running and that is the one thing I am
        terrible at." She said she would work very hard to learn to draw better,
        and hoped we would not be very impatient with her.
        <br />
        <br />
        There were, in all, only six samples of her work enclosed in the
        envelope. (All of her work was unsigned--a minor enough fact, but at the
        time, a disproportionately refreshing one. Bambi Kramer's and
        Ridgefield's pictures had all been either signed or-- and it somehow
        seemed even more irritating--initialled.) After thirteen years, I not
        only distinctly remember all six of Sister Irma's samples, but four of
        them I sometimes think I remember a trifle too distinctly for my own
        peace of mind. Her best picture was done in water colors, on brown
        paper. (Brown paper, especially wrapping paper, is very pleasant, very
        cosy to paint on. Many an experienced artist has used it when he wasn't
        up to anything grand or grandiose.) The picture, despite its confining
        size (it was about ten by twelve inches), was a highly detailed
        depiction of Christ being carried to the sepulchre in Joseph of
        Arimathea's garden. In the far right foreground, two men who seemed to
        be Joseph's servants were rather awkwardly doing the carrying. Joseph of
        Arimathea followed directly behind them--bearing himself, under the
        circumstances, perhaps a trifle too erectly. At a respectably
        subordinate distance behind Joseph came the women of Galilee, mixed in
        with a motley, perhaps gate-crashing crowd of mourners, spectators,
        children, and no less than three frisky, impious mongrels. For me, the
        major figure in the picture was a woman in the left foreground, facing
        the viewer. With her right hand raised overhead, she was frantically
        signalling to someone-- her child, perhaps, or her husband, or possibly
        the viewer--to drop everything and hurry over. Two of the women, in the
        front rank of the crowd, wore halos. Without a Bible handy, I could only
        make a rough guess at their identity. But I immediately spotted Mary
        Magdalene. At any rate, I was positive I had spotted her. She was in the
        middle foreground, walking apparently self-detached from the crowd, her
        arms down at her sides. She wore no part of her grief, so to speak, on
        her sleeve--in fact, there were no outward signs at all of her late,
        enviable connections with the Deceased. Her face, like all the other
        faces in the picture, had been done in a cheap-priced, ready-made
        flesh-tint. It was painfully clear that Sister Irma herself had found
        the color unsatisfactory and had tried her unadvised, noble best to tone
        it down somehow. There were no other serious flaws in the picture. None,
        that is, worthy of anything but cavilling mention. It was, in any
        conclusive sense, an artist's picture, steeped in high, high, organized
        talent and God knows how many hours of hard work.
        <br />
        <br />
        One of my first reactions, of course, was to run with Sister Irma's
        envelope over to M. Yoshoto. But, once again, I kept my seat. I didn't
        care to risk having Sister Irma taken away from me. At length, I just
        closed her envelope with care and placed it to one side of my desk, with
        the exciting plan to work on it that night, in my own time. Then, with
        far more tolerance than I'd thought I had in me, almost with good will,
        I spent the rest of the afternoon doing overlay corrections on some male
        and female nudes (sans sex organs) that R. Howard Ridgefield had
        genteely and obscenely drawn.
        <br />
        <br />
        Toward dinner time, I opened three buttons of my shirt and stashed away
        Sister Irma's envelope where neither thieves, nor, just to play safe,
        the Yoshotos, could break in.
        <br />
        <br />A tacit but iron-bound procedure covered all evening meals at Les
        Amis Des Vieux MaRres. Mme. Yoshoto got up from her desk promptly at
        five-thirty and went upstairs to prepare dinner, and Mr. Yoshoto and I
        followed--fell into single file, as it were--at six sharp. There were no
        side trips, however essential or hygienic. That evening, however, with
        Sister Irma's envelope warm against my chest, I had never felt more
        relaxed. In fact, all through dinner, I couldn't have been more
        outgoing. I gave away a lulu of a Picasso story that had just reached
        me, one that I might have put aside for a rainy day. M. Yoshoto scarcely
        lowered his Japanese newspaper to listen to it, but Mme. Yoshoto seemed
        responsive, or, at least, not unresponsive. In any case, when I was
        finished with it, she spoke to me for the first time since she had asked
        me that morning if I would like an egg. She asked me if I were sure I
        wouldn't like a chair in my room. I said quickly,
        <br />
        <br />
        "Non, non-merci, madame." I said that the way the floor cushions were
        set right up against the wall, it gave me a good chance to practice
        keeping my back straight. I stood up to show her how sway-backed I was.
        <br />
        <br />
        After dinner, while the Yoshotos were discussing, in Japanese, some
        perhaps provocative topic, I asked to be excused from the table. M.
        Yoshoto looked at me as if he weren't quite sure how I'd got into his
        kitchen in the first place, but nodded, and I walked quickly down the
        hall to my room. When I had turned on the overhead light and closed the
        door behind me, I took my drawing pencils out of my pocket, then took
        off my jacket, unbuttoned my shirt, and sat down on a floor cushion with
        Sister Irma's envelope in my hands. Till past four in the morning, with
        everything I needed spread out before me on the floor, I attended to
        what I thought were Sister Irma's immediate, artistic wants.
        <br />
        <br />
        The first thing I did was to make some ten or twelve pencil sketches.
        Rather than go downstairs to the instructors' room for drawing paper, I
        drew the sketches on my personal notepaper, using both sides of the
        sheet. When that was done, I wrote a long, almost an endless, letter.
        <br />
        <br />
        I've been as saving as an exceptionally neurotic magpie all my life, and
        I still have the next-to-the-last draft of the letter I wrote to Sister
        Irma that June night in 1939. I could reproduce all of it here verbatim,
        but it isn't necessary. I used the bulk of the letter, and I mean bulk,
        to suggest where and how, in her major picture, she'd run into a little
        trouble, especially with her colors. I listed a few artist's supplies
        that I thought she couldn't do without, and included approximate costs.
        I asked her who Douglas Bunting was. I asked where I could see some of
        his work. I asked her (and I knew what a long shot it was) if she had
        ever seen any reproductions of paintings by Antonello da Messina. I
        asked her to please tell me how old she was, and assured her, at great
        length, that the information, if given, wouldn't go beyond myself. I
        said the only reason that I was asking was that the information would
        help me to instruct her more efficiently. Virtually in the same breath,
        I asked if she were allowed to have visitors at her convent.
        <br />
        <br />
        The last few lines (or cubic feet) of my letter should, I think, be
        reproduced here-- syntax, punctuation, and all.
        <br />
        <br />. . . Incidentally, if you have a command of the French language,
        I hope you will let me know as I am able to express myself very
        precisely in that language, having spent the greater part of my youth
        chiefly in Paris, France.
        <br />
        <br />
        Since you are quite obviously concerned about drawing running figures,
        in order to convey the technique to your pupils at the Convent, I am
        enclosing a few sketches I have drawn myself that may be of use. You
        will see that I have drawn them rather rapidly and they are by no means
        perfect or even quite commendable, but I believe they will show you the
        rudiments about which you have expressed interest. Unfortunately the
        director of the school does not have any system in the method of
        teaching here, I am very much afraid. I am delighted that you are
        already so well advanced, but I have no idea what he expects me to do
        with my other students who are very retarded and chiefly stupid, in my
        opinion.
        <br />
        <br />
        Unfortunately, I am an agnostic; however, I am quite an admirer of St.
        Francis of Assisi from a distance, it goes without saying. I wonder if
        perhaps you are thoroughly acquainted with what he (St. Francis of
        Assisi) said when they were about to cauterise one of his eyeballs with
        a red-hot, burning iron? He said as follows: "Brother Fire, God made you
        beautiful and strong and useful; I pray you be courteous to me." You
        paint slightly the way he spoke, in many pleasant ways, in my opinion.
        Incidentally, may I ask if the young lady in the foreground in the blue
        outfit is Mary Magdalene? I mean in the picture we have been discussing,
        of course. If she is not, I have been sadly deluding myself. However,
        this is no novelty.
        <br />
        <br />I hope you will consider me entirely at your disposal as long as
        you are a student at Les Amis Des Vieux Maitres. Frankly, I think you
        are greatly talented and would not even be slightly startled if you
        developed into a genius before many years have gone by. I would not
        falsely encourage you in this matter. That is one reason why I asked you
        if the young lady in the foreground in the blue outfit was Mary
        Magdalene, because if it was, you were using your incipient genius
        somewhat more than your religious inclinations, I am afraid. However,
        this is nothing to fear, in my opinion.
        <br />
        <br />
        With sincere hope that you are enjoying completely perfect health, I am,
        Very respectfully yours, (signed)
        <br />
        <br />
        JEAN DE DAUMIER-SMITH Staff Instructor
        <br />
        <br />
        Les Amis Des Vieux Maltres
        <br />
        <br />
        P.S. I have nearly forgotten that students are supposed to submit
        envelopes every second Monday to the school. For your first assignment
        will you kindly make some outdoor sketches for me? Do them very freely
        and do not strain. I am unaware, of course, how much time they give you
        for your personal drawing at your Convent and hope you will advise me.
        Also I beg you to buy those necessary supplies I took the liberty of
        advocating, as I would like you to begin using oils as soon as possible.
        If you will pardon my saying so, I believe you are too passionate to
        paint just in watercolors and never in oils indefinitely. I say that
        quite impersonally and do not mean to be obnoxious; actually, it is
        intended as a compliment. Also please send me all of your old former
        work that you have on hand, as I am eager to see it. The days will be
        insufferable for me till your next envelope arrives, it goes without
        saying.
        <br />
        <br />
        If it is not overstepping myself, I would greatly appreciate your
        telling me if you find being a nun very satisfactory, in a spiritual
        way, of course. Frankly, I have been studying various religions as a
        hobby ever since I read volumes 36, 44, 45 of the Harvard Classics,
        which you may be acquainted with. I am especially delighted with Martin
        Luther, who was a Protestant, of course. Please do not be offended by
        this. I advocate no doctrine; it is not my nature to do so. As a last
        thought, please do not forget to advise me as to your visiting hours, as
        my weekends are free as far as I know and I may happen to be in your
        environs some Saturday by chance. Also please do not forget to inform me
        if you have a reasonable command of the French language, as for all
        intents and purposes I am comparatively speechless in English owing to
        my varied and largely insensible upbringing.
        <br />
        <br />I mailed my letter and drawings to Sister Irma around three-thirty
        in the morning, going out to the street to do it. Then, literally
        overjoyed, I undressed myself with thick fingers and fell into bed.
        <br />
        <br />
        Just before I fell asleep, the moaning sound again came through the wall
        from the Yoshotos' bedroom. I pictured both Yoshotos coming to me in the
        morning and asking me, begging me, to hear their secret problem out, to
        the last, terrible detail. I saw exactly how it would be. I would sit
        down between them at the kitchen table and listen to each of them. I
        would listen, listen, listen, with my head in my hands--till finally,
        unable to stand it any longer, I would reach down into Mme. Yoshoto's
        throat, take up her heart in my hand and warm it as I would a bird.
        Then, when all was put right, I would show Sister Irma's work to the
        Yoshotos, and they would share my joy.
        <br />
        <br />
        The fact is always obvious much too late, but the most singular
        difference between happiness and joy is that happiness is a solid and
        joy a liquid. Mine started to seep through its container as early as the
        next morning, when M. Yoshoto dropped by at my desk with the envelopes
        of two new students. I was working on Bambi Kramer's drawings at the
        time, and quite spleenlessly, knowing as I did that my letter to Sister
        Irma was safely in the mail. But I was no where even nearly prepared to
        face the freakish fact that there were two people in the world who had
        less talent for drawing than either Bambi or R. Howard Ridgefield.
        Feeling virtue go out of me, I lit a cigarette in the instructors' room
        for the first time since I'd joined the staff. It seemed to help, and I
        turned back to Bambi's work. But before I'd taken more than three or
        four drags, I felt, without actually glancing up and over, that M.
        Yoshoto was looking at me. Then, for confirmation, I heard his chair
        being pushed back. As usual, I got up to meet him when he came over. He
        explained to me, in a bloody irritating whisper, that he
        <br />
        <br />
        personally had no objection to smoking, but that, alas, the school's
        policy was against smoking in the instructors' room. He cut short my
        profuse apologies with a magnanimous wave of his hand, and went back
        over to his and Mme. Yoshoto's side of the room. I wondered, in a real
        panic, how I would manage to get sanely through the next thirteen days
        to the Monday when Sister Irma's next envelope was due.
        <br />
        <br />
        That was Tuesday morning. I spent the rest of the working day and all
        the working portions of the next two days keeping myself feverishly
        busy. I took all of Bambi Kramer's and R. Howard Ridgefield's drawings
        apart, as it were, and put them together with brand-new parts. I
        designed for both of them literally dozens of insulting, subnormal, but
        quite constructive, drawing exercises. I wrote long letters to them. I
        almost begged R. Howard Ridgefield to give up his satire for a while. I
        asked Bambi, with maximum delicacy, to please hold off, temporarily,
        submitting any more drawings with titles kindred to "Forgive Them Their
        Trespasses." Then, Thursday mid-afternoon, feeling good and jumpy, I
        started in on one of the two new students, an American from Bangor,
        Maine, who said in his questionnaire, with wordy, Honest-John integrity,
        that he was his own favorite artist. He referred to himself as a
        realist-abstractionist. As for my after-school hours, Tuesday evening I
        took a bus into Montreal proper and sat through a Cartoon Festival Week
        program at a third-rate movie house--which largely entailed being a
        witness to a succession of cats being bombarded with champagne corks by
        mice gangs. Wednesday evening, I gathered up the floor cushions in my
        room, piled them three high, and tried to sketch from memory Sister
        Irma's picture of Christ's burial.
        <br />
        <br />
        I'm tempted to say that Thursday evening was peculiar, or perhaps
        macabre, but the fact is, I have no bill-filling adjectives for Thursday
        evening. I left Les Amis after dinner and went I don't know where
        --perhaps to a movie, perhaps for just a long walk; I can't remember,
        and, for once, my diary for 1939 lets me down, too, for the page I need
        is a total blank.
        <br />
        <br />I know, though, why the page is a blank. As I was returning from
        wherever I'd spent the evening --and I do remember that it was after
        dark --I stopped on the sidewalk outside the school and looked into the
        lighted display window of the orthopedic appliances shop. Then something
        altogether hideous happened. The thought was forced on me that no matter
        how coolly or sensibly or gracefully I might one day learn to live my
        life, I would always at best be a visitor in a garden of enamel urinals
        and bedpans, with a sightless, wooden dummy-deity standing by in a
        marked-down rupture truss. The thought, certainly, couldn't have been
        endurable for more than a few seconds. I remember fleeing upstairs to my
        room and getting undressed and into bed without so much as opening my
        diary, much less making an entry.
        <br />
        <br />I lay awake for hours, shivering. I listened to the moaning in the
        next room and I thought, forcibly, of my star pupil. I tried to
        visualize the day I would visit her at her convent. I saw her coming to
        meet me--near a high, wire fence--a shy, beautiful girl of eighteen who
        had not yet taken her final vows and was still free to go out into the
        world with the Peter Abelard-type man of her choice. I saw us walking
        slowly, silently, toward a far, verdant part of the convent grounds,
        where suddenly, and without sin, I would put my arm around her waist.
        The image was too ecstatic to hold in place, and, finally, I let go, and
        fell asleep.
        <br />
        <br />I spent all of Friday morning and most of the afternoon at hard
        labor trying, with the use of overlay tissue, to make recognizable trees
        out of a forest of phallic symbols the man from Bangor, Maine, had
        consciously drawn on expensive linen paper. Mentally, spiritually, and
        physically, I was feeling pretty torpid along toward four-thirty in the
        afternoon, and I only half stood up when M. Yoshoto came over to my desk
        for an instant. He handed something to me--handed it to me as
        impersonally as the average waiter distributes menus. It was a letter
        from the Mother Superior of Sister Irma's convent, informing M. Yoshoto
        that Father Zimmermann, through circumstances outside his control, was
        forced to alter his decision to allow Sister Irma to study at Les Amis
        Des Vieux Maitres. The writer said she deeply regretted any
        inconveniences or
        <br />
        <br />
        confusions this change of plans might cause the school. She sincerely
        hoped that the first tuition payment of fourteen dollars might be
        refunded to the diocese.
        <br />
        <br />
        The mouse, I've been sure for years, limps home from the site of the
        burning ferris wheel with a brand-new, airtight plan for killing the
        cat. After I'd read and reread and then, for great, long minutes, stared
        at the Mother Superior's letter, I suddenly broke away from it and wrote
        letters to my four remaining students, advising them to give up the idea
        of becoming artists. I told them, individually, that they had absolutely
        no talent worth developing and that they were simply wasting their own
        valuable time as well as the school's. I wrote all four letters in
        French. When I was finished, I immediately went out and mailed them. The
        satisfaction was short-lived, but very, very good while it lasted.
        <br />
        <br />
        When it came time to join the parade to the kitchen for dinner, I asked
        to be excused. I said I wasn't feeling well. (I lied, in 1939, with far
        greater conviction than I told the truth--so I was positive that M.
        Yoshoto looked at me with suspicion when I said I wasn't feeling well.)
        Then I went up to my room and sat down on a cushion. I sat there for
        surely an hour, staring at a daylit hole in the window blind, without
        smoking or taking off my coat or loosening my necktie. Then, abruptly, I
        got up and brought over a quantity of my personal notepaper and wrote a
        second letter to Sister Irma, using the floor as a desk.
        <br />
        <br />I never mailed the letter. The following reproduction is copied
        straight from the original.
        <br />
        <br />
        Montreal, Canada June 28, 1939
        <br />
        <br />
        DEAR SISTER IRMA,
        <br />
        <br />
        Did I, by chance, say anything obnoxious or irreverent to you in my last
        letter that reached the attention of Father Zimmermann and caused you
        discomfort in some way? If this is the case, I beg you to give me at
        least a reasonable chance to retract whatever it was I may have
        unwittingly said in my ardor to become friends with you as well as
        student and teacher. Is this asking too much? I do not believe it is.
        <br />
        <br />
        The bare truth is as follows: If you do not learn a few more rudiments
        of the profession, you will only be a very, very interesting artist the
        rest of your life instead of a great one. This is terrible, in my
        opinion. Do you realize how grave the situation is?
        <br />
        <br />
        It is possible that Father Zimmermann made you resign from the school
        because he thought it might interfere with your being a competent nun.
        If this is the case, I cannot avoid saying that I think it was very rash
        of him in more ways than one. It would not interfere with your being a
        nun. I live like an evil-minded monk myself. The worst that being an
        artist could do to you would be that it would make you slightly unhappy
        constantly. However, this is not a tragic situation, in my opinion. The
        happiest day of my life was many years ago when I was seventeen. I was
        on my way for lunch to meet my mother, who was going out on the street
        for the first time after a long illness, and I was feeling ecstatically
        happy when suddenly, as I was coming in to the Avenue Victor Hugo, which
        is a street in Paris, I bumped into a chap without any nose. I ask you
        to please consider that factor, in fact I beg you. It is quite pregnant
        with meaning.
        <br />
        <br />
        It is also possible that Father Zimmermann caused you to stop
        matriculating for the reason perhaps that your convent lacks funds to
        pay the tuition. I frankly hope this is the case, not only because it
        relieves my mind, but in a practical sense. If this is indeed the case,
        you have only to say the word and I will offer my services gratis for an
        indefinite period of time. Can we discuss this matter further? May I ask
        again when your visiting days at the convent are? May I be free to plan
        to visit you at the convent next Saturday afternoon, July 6, between 3
        and 5 o'clock in the afternoon, dependent upon the schedule of trains
        between Montreal and Toronto? I await your reply with great anxiety.
        <br />
        <br />
        With respect and admiration,
        <br />
        <br />
        Sincerely yours,
        <br />
        <br />
        (signed)
        <br />
        <br />
        JEAN DE DAUMIER-SMITH
        <br />
        <br />
        Staff Instructor
        <br />
        <br />
        Les Amis Des Vieux Maltres
        <br />
        <br />
        P.S. In my last letter I casually asked if the young lady in the blue
        outfit in the foreground of your religious picture was Mary Magdalene,
        the sinner. If you have not as yet replied to my letter, please go on
        refraining. It is possible that I was mistaken and I do not willfully
        invite any disillusions at this point in my life. I am willing to stay
        in the dark.
        <br />
        <br />
        Even today, as late as now, I have a tendency to wince when I remember
        that I brought a dinner suit up to Les Amis with me. But bring one I
        did, and after I'd finished my letter to Sister Irma, I put it on. The
        whole affair seemed to call out for my getting drunk, and since I had
        never in my life been drunk (for fear that excessive drinking would
        shake the hand that painted the pictures that copped the three first
        prizes, etc.), I felt compelled to dress for the tragic occasion.
        <br />
        <br />
        While the Yoshotos were still in the kitchen, I slipped downstairs and
        telephoned the Windsor Hotel--which Bobby's friend, Mrs. X, had
        recommended to me before I'd left New York. I reserved a table for one,
        for eight o'clock.
        <br />
        <br />
        Around seven-thirty, dressed and slicked up, I stuck my head outside my
        door to see if either of the Yoshotos were on the prowl. I didn't want
        them to see me in my dinner jacket, for some reason. They weren't in
        sight, and I hurried down to the street and began to look for a cab. My
        letter to Sister Irma was in the inside pocket of my jacket. I intended
        to read it over at dinner, preferably by candlelight.
        <br />
        <br />I walked block after block without so much as seeing a cab at all,
        let alone an empty one. It was rough going. The Verdun section of
        Montreal was in no sense a dressy neighborhood, and I was convinced that
        every passer-by was giving me a second, basically censorious look. When,
        finally, I came to the lunch bar where I'd bolted the "Coney Island
        Red-Hots" on Monday, I decided to let my reservation at the Hotel
        Windsor go by the board. I went into the lunch bar, sat down in an end
        booth, and kept my left hand over my black tie while I ordered soup,
        rolls and black coffee. I hoped that the other patrons would think I was
        a waiter on his way to work.
        <br />
        <br />
        While I was on my second cup of coffee, I took out my unmailed letter to
        Sister Irma and reread it. The substance of it seemed to me a trifle
        thin, and I decided to hurry back to Les Amis and touch it up a bit. I
        also thought over my plans to visit Sister Irma, and wondered if it
        might not be a good idea to pick up my train reservations later that
        same evening. With those two thoughts in mind--neither of which really
        gave me the sort of lift I needed--I left the lunch bar and walked
        rapidly back to school.
        <br />
        <br />
        Something extremely out of the way happened to me some fifteen minutes
        later. A statement, I'm aware, that has all the unpleasant earmarks of a
        build-up, but quite the contrary is true. I'm about to touch on an
        extraordinary experience, one that still strikes me as having been quite
        transcendent, and I'd like, if possible, to avoid seeming to pass it off
        as a case, or even a borderline case, of genuine mysticism. (To do
        otherwise, I feel, would be tantamount to implying or stating that the
        difference in spiritual sorties between St. Francis and the average,
        highstrung, Sunday leper-kisser is only a vertical one.)
        <br />
        <br />
        In the nine o'clock twilight, as I approached the school building from
        across the street, there was a light on in the orthopedic appliances
        shop. I was startled to see a live person in the shopcase, a hefty girl
        of about thirty, in a green, yellow and lavender chiffon dress. She was
        changing the truss on the wooden dummy. As I came up to the show window,
        she had evidently just taken off the old truss; it was under her left
        arm (her right "profile" was toward me), and she was lacing up the new
        one on the dummy. I stood watching her, fascinated, till suddenly she
        sensed, then saw, that she was being watched. I quickly smiled--to show
        her that this was a nonhostile figure in the tuxedo in the twilight on
        the other side of the glass--but it did no good. The girl's confusion
        was out of all normal proportion. She blushed, she dropped the removed
        truss, she stepped back on a stack of irrigation basins--and her feet
        went out from under her. I reached out to her instantly, hitting the
        tips of my fingers on the glass. She landed heavily on
        <br />
        <br />
        her bottom, like a skater. She immediately got to her feet without
        looking at me. Her face still flushed, she pushed her hair back with one
        hand, and resumed lacing up the truss on the dummy. It was just then
        that I had my Experience. Suddenly (and I say this, I believe, with all
        due self-consciousness), the sun came up and sped toward the bridge of
        my nose at the rate of ninety-three million miles a second. Blinded and
        very frightened--I had to put my hand on the glass to keep my balance.
        The thing lasted for no more than a few seconds. When I got my sight
        back, the girl had gone from the window, leaving behind her a shimmering
        field of exquisite, twice-blessed, enamel flowers.
        <br />
        <br />I backed away from the window and walked around the block twice,
        till my knees stopped buckling. Then, without daring to venture another
        look into the shop window, I went upstairs to my room and lay down on my
        bed. Some minutes, or hours later, I made, in French, the following
        brief entry in my diary: "I am giving Sister Irma her freedom to follow
        her own destiny. Everybody is a nun." (Tout le monde est une nonne. )
        <br />
        <br />
        Before going to bed for the night, I wrote letters to my four
        just-expelled students, reinstating them. I said a mistake had been made
        in the administration department. Actually, the letters seemed to write
        themselves. It may have had something to do with the fact that, before
        sitting down to write, I'd brought a chair up from downstairs.
        <br />
        <br />
        It seems altogether anticlimactic to mention it, but Les Amis Des Vieux
        Maitres closed down less than a week later, for being improperly
        licensed (for not being licensed at all, as a matter of fact). I packed
        up and joined Bobby, my stepfather, in Rhode Island, where I spent the
        next six or eight weeks, till art school reopened, investigating that
        most interesting of all summer-active animals, the American Girl in
        Shorts.
        <br />
        <br />
        Right or wrong, I never again got in touch with Sister Irma.
        <br />
        <br />
        Occasionally, I still hear from Bambi Kramer, though. The last I heard,
        she'd branched over into designing her own Christmas cards. They'll be
        something to see, if she hasn't lost her touch.
      </Container>
    </div>
  );
};

export default BluePeriod;
