import React from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import firebaseApp, { auth } from 'initFirebase';
import { NavLink, useNavigate } from 'react-router-dom';
import { Input, InputGroup, FormGroup, Button } from 'Components/FormItems';
import { getDatabase, ref, child, get } from 'firebase/database';
import styled from 'styled-components';

const db = getDatabase(firebaseApp);

const Page = styled.div`
  padding: 32px 48px;
  height: 100%;
  min-height: 100vh;
  h1 {
    font-size: 32px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 0;
  }
  li {
    border: 1px solid #ccc;
    // border: 1px solid #2a317d;
    border: 1px solid #a4a4bc;
    border-radius: 3px;
    padding: 16px;
    // background: #fff;
    // background: #f7f7f7;
    margin-right: 16px;
    margin-top: 16px;
    width: 24%;
    div {
      margin-bottom: 4px;
      // background: #fff;
      // background: #f7f7f7;
    }
    // b {
    //   color: #cf2e2e;
    // }
  }
  .comments {
    white-space: pre-wrap;
  }
`;




function createStory(possibilities) {
  const finalText = possibilities.map(exhaustPossility)
  return finalText
}

// function getRandomInt(min, max) {
//   min = Math.ceil(min);
//   max = Math.floor(max);
//   return Math.floor(Math.random() * (max - min + 1)) + min;
// }
const random = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;


function exhaustPossility(option) {
  const randomIdx = random(0, option.length - 1)
  const selected = option[randomIdx] 
  console.log('random:', randomIdx)
  console.log('selected:', selected)
  if (selected instanceof Array) {
    return exhaustPossility(selected)
  }
  return selected + ' '
}



export default () => {
  const navigate = useNavigate();


  const text = createStory(STORY_POSSIBILITIES)
  
  // if (isLoading) return <div>Loading</div>;
  return (
    <Page>
      <h1>Story</h1>
      <div>
        ubi amor, ibi oculus est
      </div>
      {text}
      
    </Page>
  );
};


const STORY_POSSIBILITIES = [
  [
  'When to the sessions of sweet silent thought',
  'When in disgrace with fortune and mens eyes',
  'When in disgrace with fortune and mens eyes',
  'I all alone bewail my outcast state',
  '',
  ],
  ['and trouble death heaven with bootless cries'],
  ['full many a glorious morning I have seen'],
  [
    [
    'tomorrow and tomorrow and tomorrow',
    'tomorrow and tomorrow and tomorrow',
    'tomorrow, tomorrow, theres always tomorrow',
    ],
    ['put money in thy purse', '', ''],
    ['and look upon myself and curse my fate'],
  ],
  ['wishing my like to one more rich in hope', '', 'but if I think on thee, dear friend'],
  [
    [
      ['featured like him, like him with friends possessed'],
      ['featured like him'],
      ['hot like him'],
      ['hot like him']
    ],
    ['with what I most enjoy contented least'],
    ''
  ],
  ['for thy sweet love remembered such wealth brings'],
  [
    [
      [
        'that then I scorn to change my place with kings',
        [
          'then like to the lark at break of day arising from sullen earth', 
        ]
      ]
    ]
  ]
]
